import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const Defend = () => {
  const calendly =
    "https://calendly.com/dymd/braces-omaha-defend-your-mouth-day"
  return (
    <SharedStateProvider>
      <Layout language="en">
        <SEO
          title="Defend Your Mouth Day"
          description="At Braces Omaha's Defend Your Mouth Day event, we are protecting smiles by providing children and teens ages 8 to 14 with a FREE professionally fitted mouth guard!"
        />
        <div className="dmd-23">
          <div className="dmd-23__section">
            <div className="dmd-23__container">
              <div className="dmd-23__grid vcenter">
                <div>
                  <img
                    className="logo-img"
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2023-dymd-logo-alt"
                    alt="program logo"
                  />
                  <img
                    className="dmd-23__desktop logo-img"
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2023-dymd-hero-headline-alt"
                    alt="defending young smiles"
                  />
                  <img
                    className="dmd-23__mobile logo-img"
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2023-dymd-hero-headline-mobile-alt"
                    alt="defending young smiles"
                  />

                  <img
                    className="dmd-23__mobile logo-img"
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2023-dymd-hero-image"
                    alt="action shot of lacrosse game"
                  />

                  <h1 className="dmd-23__heading">
                    Helping Defend Young Smiles
                  </h1>
                  <span />
                  <p>
                    Did you know that 60% of dental injuries can be eliminated
                    by wearing a mouth guard? Many sports and activities that
                    involve the risk of injury require mouth guards to protect
                    the teeth, gums, mouth, and jaw from dental trauma.
                  </p>

                  <p>
                    During our Defend Your Mouth Day event, Dr. Clarke Stevens
                    and the team at Braces Omaha provided free professionally
                    fitted mouth guards to kids and teens ages 8 to 14.
                  </p>
                  <p>
                    <strong>
                      Thank you to all those who attended Defend Your Mouth Day!
                      We look forward to seeing you next year!
                    </strong>
                  </p>
                  {/* <a
                    href={calendly}
                    target="_blank"
                    title="Schedule an appointment"
                    className="dmd-23__btn">
                    Schedule Appointment
                  </a> */}
                </div>
                <img
                  className="dmd-23__desktop"
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2023-dymd-hero-image"
                  alt="action shot of lacrosse game"
                />
              </div>
            </div>
          </div>

          <div className="dmd-23__section dmd-23__color-back">
            <div className="dmd-23__container">
              <div className="dmd-23__grid vcenter">
                <div>
                  <img
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2023-dydm-did-you-know-image"
                    alt="image collage"
                  />
                </div>
                <div>
                  <h3 className="dmd-23__heading">DID YOU KNOW?</h3>
                  <p>
                    More than 5 million teeth are knocked out each year, often
                    due to sports-related injuries. Remember, damaged teeth do
                    not grow back. Wearing a properly fitted mouth guard is an
                    easy way to protect teeth. In fact, mouth guards help avoid
                    200,000 oral injuries every year.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="dmd-23__section">
            <div className="dmd-23__container">
              <div className="dmd-23__grid reverse vcenter">
                <div>
                  <img
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2023-dydm-ada-image"
                    alt="image collage of people playing sports"
                  />
                </div>

                <div>
                  <h3 className="dmd-23__heading">
                    The American Dental Association (ADA) recommends wearing a
                    mouth guard for the following sports:
                  </h3>
                  <div className="dmd-23__sports-list">
                    <ul>
                      <li>Soccer</li>
                      <li>Football</li>
                      <li>Softball</li>
                      <li>Gymnastics</li>
                      <li>Acrobatics</li>
                      <li>Equestrian events</li>
                      <li>Basketball</li>
                      <li>Ice hockey</li>
                      <li>Bicycling</li>
                    </ul>
                    <ul>
                      <li>Skiing</li>
                      <li>Extreme sports</li>
                      <li>Boxing</li>
                      <li>Field events</li>
                      <li>Volleyball</li>
                      <li>Handball</li>
                      <li>Inline skating</li>
                      <li>Lacrosse</li>
                      <li>Field hockey</li>
                    </ul>
                    <ul>
                      <li>Rugby</li>
                      <li>Water polo</li>
                      <li>Martial arts</li>
                      <li>Wrestling</li>
                      <li>Shot put</li>
                      <li>Racquetball</li>
                      <li>Skateboarding</li>
                      <li>Weight lifting</li>
                      <li>Squash</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="dmd-23__section dmd-23__color-back">
            <div className="dmd-23__container">
              <ImageSlider
                useDots
                adaptiveHeight
                images={[
                  "BROM/DEV/defend-2023-1",
                  "BROM/DEV/defend-2023-2",
                  "BROM/DEV/defend-2023-3",
                  "BROM/DEV/defend-2023-4"
                ]}
              />
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default Defend
